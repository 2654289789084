import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Colophon from '../components/colophon';
import { StaticImage } from 'gatsby-plugin-image';

function ThankYou() {
  return (
    <Layout>
      <Seo title="Thank You" />

      <div className='h-screen bg-background'>
        <div className=" px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
              <div className="py-16">
                  <div className="pb-10 text-center">
                    <StaticImage
                      src="../images/bailey-buildings-mark.png"
                      quality={95}
                      loading='eager'
                      formats={['AUTO', 'WEBP']}
                      className="object-fit mx-auto "
                      alt="Bailey Buildings Logo"
                    />
                  </div>
                <div className="text-center">
                  <h1 className="mt-2 text-4xl font-bold tracking-tight text-light sm:text-5xl">
                    Thank You.
                  </h1>
                  <p className="mt-2 text-base text-light">
                    Your Message Has Been Sent Successfully
                  </p>
                  <div className="mt-6">
                    <a
                      href="/"
                      className="text-base font-medium text-light hover:text-secondary"
                    >
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

    </Layout>
  );
}

export default ThankYou;
